import React from "react";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { IMAGE_URL_ROOT } from 'Util/API';

import { Table, List, Button } from "antd";

const Activities = (props) => {
  const { t } = useTranslation('orderDetailsPage');

  const column = [
    {
      title: t('activities.tableColumn.dateAndTime'),
      dataIndex: "createdAt",
      key: "createdAt",
      width: 100,
      render: text => {
        return (
          <div>
            <div>{moment(text).format("Do MMM YYYY")}</div>
            <div>{moment(text).format("h:mm a")}</div>
          </div>
        )
      }
    },
    {
      title: t('activities.tableColumn.status'),
      dataIndex: "statusText",
      key: "statusText",
      width: 100,
      render: (text) => (
        text || 'N/A'
      )
    },
    {
      title: 'Description',
      dataIndex: "description",
      key: "description",
      width: 150,
      render: (text) => (
        text || 'N/A'
      )
    },
    {
      title: t('activities.tableColumn.location'),
      dataIndex: "location",
      key: "location",
      width: 100,
      render: (text) => (
        text || 'N/A'
      )
    },
    {
      title: 'POD',
      key: "pod",
      width: 100,
      render: (data, record) => {
        return record.podName ? (
          <div>
            <p>
              <div>Name: {record.podName || '-'}</div>
              <div>Identification: {record.podNRIC || '-'}</div>
              <div>Phone no: {record.podMobile || '-'}</div>
            </p>

            {record.podSign && (
            <a target="_blank" rel='noopener noreferrer' href={IMAGE_URL_ROOT + '/' + record.podSign}>
              <img style={{ width: 100, height: 'auto' }} src={IMAGE_URL_ROOT + '/' + record.podSign} alt="Forest" />
            </a>
            )}

            {record.podPicture && (
                <div
                    style={{
                        margin: "8px 0",
                        position: "relative",
                        display: "flex",
                        width: "100%",
                    }}
                >
                    {record.podPicture.map((item, index) => (
                      <Button style={{ marginLeft: 10 }}>
                        <a href={IMAGE_URL_ROOT + '/' + item} target="_blank" rel='noopener noreferrer'>
                          {`Pic ${index + 1}`}
                        </a>
                      </Button>
                    ))}
                </div>
            )}
          </div>
        ) : 'N/A'
      }
    }
  ];

  return (
    <List
      bordered
      style={{ backgroundColor: "white", marginBottom: "20px" }}
      header={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p className="heading3" style={{ margin: 0 }}>
            {t('activities.title')}
          </p>
        </div>
      }
    >
      <Table
        loading={props.isLoading}
        columns={column}
        dataSource={props.activitiesData}
        scroll={{ x: 1290 }}
      />
    </List>
  )
}

export default Activities;
