import React, { useState, useEffect, useContext } from "react";
import { message } from 'antd';


import { get, post, update, remove } from 'Util/API';
import PageWrapper from 'Components/PageWrapper'
import { GlobalContext } from 'Store/store';
import ShowOn from 'Util/ShowOn';

import OrderDetailsContent from "./OrderDetailsContent";

import { mixPanelTrack } from "Util/CustomFunctions";

const OrderDetails = (props) => {
  const [state] = useContext(GlobalContext);
  const [activities, setActivities] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [rejectReasons, setRejectReasons] = useState(null);

  const getOrderDetails = async () => {
    const { match } = props;
    const fetch = await get(`/order/${match.params.orderId}?retrieve=quotes,service`);
    if (fetch.status === 200) {
      setOrderDetails(fetch.data.data)
    } else message.error(fetch);
  }

  useEffect(() => {
    let mount = true;
    mount && getOrderDetails();
    return () => {
      mount = false
    }
  }, [])

  useEffect(() => {
    if (orderDetails) {
      if (orderDetails?.consignmentNo) {
        getActivities(orderDetails.consignmentNo)
        getRejectReasons(orderDetails.id)
      } else {
        setActivities([])
        setRejectReasons([])
        setIsLoading(false)
      }
    }
  }, [orderDetails?.consignmentNo])

  const getActivities = async (consignmentNo) => {
    const fetch = await post(`/order/track?returnPod=true`, {
      companyId: state.global.companyDetails.id,
      consignmentNo: [consignmentNo],
      resultType: 'latestFirst'
    });
    if (fetch.status === 200) {
      setActivities(fetch.data[0]?.histories || []);
    } else message.error(fetch);
    setIsLoading(false);
  }

  const getRejectReasons = async (orderId) => {
    const fetch = await get(`/order/`+orderId+`/personnel/delReason`);
    if (fetch.status === 200) {
      setRejectReasons(fetch.data.data || []);
    } else message.error(fetch);
    setIsLoading(false);
  }

  const onCancelOrder = async ({ id, setModalState, modalState, cancelReason }) => {
    setModalState({
      ...modalState,
      isModalVisible: false
    });
    setIsLoading(true)

    const cancelOrder = await post("order/" + id + "/cancel", { reason: cancelReason });
    if (cancelOrder.status === 200) {
      getOrderDetails();
      message.success('Order cancelled.')
    } else {
      message.error(cancelOrder)
    }
    setIsLoading(false)
  }

  const onFulfilOrder = async ({ id, serviceCode, setModalState, modalState, cloneOrderData }) => {

    setModalState({
      ...modalState,
      isModalVisible: false
    });
    setIsLoading(true)

    if(cloneOrderData)
    {
        //update service code
        const dataToUpdate = {
          serviceCode: cloneOrderData ? cloneOrderData.serviceCode: cloneOrderData.serviceCode,
        }

        const updateOrderRes = await update('order/'+id, dataToUpdate);
        if (updateOrderRes.status === 200) {
          // then process order
          const dataToSubmit = {
            orderIds: [id],
            // serviceCode: cloneOrderData ? cloneOrderData.serviceCode: cloneOrderData.serviceCode,
            scheduledAt: cloneOrderData ? cloneOrderData.scheduledAt: '',
            process: true
          }

          const fulfilOrder = await post("order/process", dataToSubmit );
          if (fulfilOrder.status === 200) {
            message.success('Fulfil order successful.')
            const res = fulfilOrder.data.data;

            if(res)
            {
                window.location = `${process.env.PUBLIC_URL}/order/`+id;
            }
          } else {
            message.error(fulfilOrder)
          }
        } else message.error(updateOrderRes);
        //
    }

    setIsLoading(false);
  }

  const onCloneOrder = async ({ id, serviceCode, setModalState, modalState, cloneOrderData }) => {

    setModalState({
      ...modalState,
      isModalVisible: false
    });
    setIsLoading(true)

    if(cloneOrderData)
    {
        const dataToSubmit = {
          scheduledAt: cloneOrderData ? cloneOrderData.scheduledAt: '',
          serviceCode: cloneOrderData ? cloneOrderData.serviceCode: cloneOrderData.serviceCode,
          // note: cloneOrderData.note,
          process: true
        }

        const cloneOrder = await post("order/" + id + "/clone", dataToSubmit );
        if (cloneOrder.status === 200) {
          message.success('Clone order successful.')
          const res = cloneOrder.data.data;

          if(res)
          {
              window.open(`${process.env.PUBLIC_URL}/order/`+res.orderId+``);
          }
        } else {
          message.error(cloneOrder)
        }
    }

    setIsLoading(false);
  }


  const onRejectDriver = async ({ id, reason, setModalState, modalState, rejectDriverData }) => {

    setModalState({
      ...modalState,
      isModalVisible: false
    });
    setIsLoading(true)

    if(rejectDriverData)
    {
        const dataToSubmit = {
          reason: rejectDriverData ? rejectDriverData.reason: rejectDriverData.reason
        }

        const rejectDriver = await remove("order/" + id + "/personnel", dataToSubmit );
        if (rejectDriver.status === 200) {
          message.success('Reject driver successful.')
          const res = rejectDriver.data.data;

          if(res)
          {
              window.open(`${process.env.PUBLIC_URL}/order/`+res.orderId+``);
          }
        } else {
          message.error(rejectDriver)
        }
    }

    setIsLoading(false);
  }

  useEffect(() => {
    mixPanelTrack('ViewOrderDetails');
  }, []);

  return (
    <PageWrapper loading={isLoading}>
      <ShowOn
        on={orderDetails && activities}
        elseShow={<div style={{ height: window.innerHeight }} />}
      >
        <OrderDetailsContent
          selectedOrder={{ ...orderDetails, activities }}
          onCancelOrder={onCancelOrder}
          onCloneOrder={onCloneOrder}
          onRejectDriver={onRejectDriver}
          onFulfilOrder={onFulfilOrder}
          prevPath={props.location.state?.from}
          rejectReasons={rejectReasons}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </ShowOn>
    </PageWrapper>
  )
}

export default OrderDetails;
